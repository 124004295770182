import { ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';

import { AppData, ControllerConfig, WixCodeApi } from '../types';
import { fetchMenusData } from '../current-ma';

export const initAppDataStore = (
  appParams: ControllerConfig['appParams'],
  wixCodeApi: WixCodeApi,
  flowApi: ViewerScriptFlowAPI,
) => {
  let appDataPromise: null | Promise<AppData> = null;
  return {
    getAppData: () => {
      if (!appDataPromise) {
        appDataPromise = fetchMenusData({ appParams, wixCodeApi, flowApi });
      }
      return appDataPromise;
    },
  };
};
