import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { IControllerConfig } from '../../types';
import { controllerCurrentMA } from './current-ma';
import { controllerMAV2 } from './ma-v2';

const getGlobalController = (config: IControllerConfig) => {
  return config.isMembersAreaV2 ? controllerMAV2 : controllerCurrentMA;
};

const createController: CreateControllerFn = async (props) => {
  const { config } = props.controllerConfig;
  const globalController = getGlobalController(config as IControllerConfig);

  return globalController(props);
};

export default createController;
